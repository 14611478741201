// import App from './app.js';
//
// const app = new App();

import './module/modernizr_custom.js';

import './reusable/consent.js';
import './reusable/disabled_shake.js';
import './reusable/focus.js';
//
import './base/autohide_nav_header.js';
import './base/frame.js';
import './base/input.js';
// import './base/load.js';
import './base/smooth_scroll.js';
import './base/touch_scroll_detection.js';

// import './base/cookie.js';
