import * as Event from './../function/event.js';
import { hasClass } from './../function/class.js';
import * as Page from './../function/page.js';

function elmYPosition(eID) {
  var elm = document.getElementById(eID);

  if (!elm) {
    return;
  }

  var previousSibling = elm.previousElementSibling;
  
  if (previousSibling && previousSibling.classList.contains('anchor_position')) {
    elm = previousSibling;
  }
  
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent != document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  } return y;
}

function calcScrollBodyFade(timer, step, distance) {
  distance = Math.abs( distance );
  var strength = ( distance / 1000 );
  var progress = ( timer * step ) / distance;
  if (progress < 0.5) progress = 1 - ( progress * 2 );
  else progress = ( progress - 0.5 ) * 2;
  progress = progress * 0.2 + 0.8;
  return progress * progress * progress * progress;
}

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

function mapRange(value, fromLow, fromHigh, toLow, toHigh) {
  // First, subtract the lower bound of the initial range and scale the range to [0, 1]
  const normalized = (value - fromLow) / (fromHigh - fromLow);

  // Next, scale the normalized value to the new range and add the lower bound of the target range
  const scaled = normalized * (toHigh - toLow) + toLow;

  return scaled;
}

// function easeIn(t) {
//     return t * t;
// }

// function easeOut(t) {
//     return t * (2 - t);
// }

function easeIn(progress, power) {
  return Math.pow(progress, power);
}

function easeOut(progress, power) {
  return 1 - Math.pow(1 - progress, power);
}

const duration = 1000; // 1 second
// const accelerationInflectionPercentage = 0.5;
// const accelerationDuration = duration * accelerationInflectionPercentage;
// const decelerationDuration = duration - accelerationDuration;

function getProgress(timeElapsed, magnitude) {
  let progress;
  
  // Determine the inflection point based on the smallest of 1000px or 0.5% of the scroll distance
  const inflectionDistance = Math.min(1000, magnitude * 0.5);
  const inflectionPercentage = inflectionDistance / magnitude;

  const accelerationDuration = duration * inflectionPercentage;
  const decelerationDuration = duration - accelerationDuration;
  
  // const powerIn  = Math.ceil(2 + Math.abs(magnitude) * 0.001);
  const powerIn  = 2;
  // const powerOut = Math.ceil(2 + Math.abs(magnitude) * 0.001);
  const powerOut = Math.ceil(2 + magnitude * 0.0001);

  console.log("magnitude: "+magnitude+",powerIn: "+powerIn+",powerOut: "+powerOut);

  if (timeElapsed < accelerationDuration) {
    // Acceleration phase
    const accelerationProgress = timeElapsed / accelerationDuration;
    progress = easeIn(accelerationProgress, powerIn) * inflectionPercentage;
  } else {
    // Deceleration phase
    const decelerationElapsed = timeElapsed - accelerationDuration;
    const decelerationProgress = decelerationElapsed / decelerationDuration;
    progress = inflectionPercentage + (easeOut(decelerationProgress, powerOut) * (1 - inflectionPercentage));
  }

  return clamp(progress, 0, 1);
}

function scrollToPosition(targetX, targetY) {
  const navHeight = document.getElementById('nav-header').offsetHeight;
  
  const startX = window.scrollX || window.pageXOffset;
  let startY = window.scrollY || window.pageYOffset;
  
  targetY = clamp(targetY - navHeight - 16, 0, document.documentElement.scrollHeight - window.innerHeight);

  const vectorX = targetX - startX;
  const vectorY = targetY - startY;

  const startTime = performance.now();

  function animateScroll(currentTime) {
      const timeElapsed = currentTime - startTime;

      let progressY = getProgress(timeElapsed, Math.abs(vectorY));

      console.log("progressY: "+progressY);

      window.scrollTo(
          startX + vectorX * getProgress(timeElapsed, Math.abs(vectorX)),
          startY + vectorY * progressY
      );

      if (timeElapsed < duration) {
          requestAnimationFrame(animateScroll);
      }
  }

  requestAnimationFrame(animateScroll);
}

function smoothScroll(targetY, href) {
  scrollToPosition(0, targetY);
}

function getElementByHrefOrHash(href) {
  // Normalize the href or hash to remove the '#' character if it exists
  const normalizedHref = href.replace('#', '');

  // Attempt to find the element by ID using the normalized href
  const targetElement = document.getElementById(normalizedHref);

  return targetElement;
}

// Key press event listener
document.addEventListener('keypress', (event) => {
  const key = event.key;
  const scrollPositions = [0, 500, 1000, 5000, 10000, 15000, 20000, 25000, 30000];

  if (key >= '1' && key <= '9') {
    const index = parseInt(key, 10) - 1;
    const position = scrollPositions[index];
    scrollToPosition(0, position); // Scroll to the same position for X and Y for demonstration
  }
});

var header = document.getElementById("nav-header");
var footer = document.getElementById("nav-footer");
var headerHeight = header.offsetHeight;

function clickHandler(e) {
  e.preventDefault();

  const href = this.getAttribute("href");

  if (href !== '#' && getElementByHrefOrHash(href)) {
    window.history.pushState({}, '', href);
  } else {
    // Remove the hash by pushing the current pathname without a hash
    window.history.pushState({}, '', window.location.pathname + window.location.search);
  }

  if ( href === '#' || hasClass(this, 'anchor') ) return; // this way we dont scroll when this is clicked

  if ( href === '#top' )
  {
    smoothScroll( 0, '#' );
    return;
  }

  var nextScrollY = elmYPosition( href.replace('#', '') );
  var currentScrollY = Math.round( Page.getScrollPositionY() );

  nextScrollY = nextScrollY - 16;

  var headerNextScrollY = nextScrollY - headerHeight;

  var nextAtBottom = ( headerNextScrollY + footer.offsetHeight ) > ( document.documentElement.scrollHeight - window.innerHeight );
  if ( nextAtBottom || currentScrollY > headerNextScrollY )
  {
    nextScrollY = headerNextScrollY;
  }

  if ( nextScrollY == currentScrollY ) return;

  smoothScroll( nextScrollY, href );
}

const links = document.querySelectorAll("#toc a, a[href^='#']");

for (var i = 0; i < links.length; i++) {
  Event.addEvent("click", links[i], clickHandler );
}

document.addEventListener('DOMContentLoaded', function() {
  const targetElement = getElementByHrefOrHash(window.location.hash);

  if (targetElement) {
    setTimeout(() => { // Ensure all page elements are fully loaded
      const targetPosition = elmYPosition(targetElement.id);
      const headerHeight = document.getElementById('nav-header').offsetHeight;
      const scrollToPosition = targetPosition - headerHeight - 16; // Additional offset if needed
      window.scrollTo(0, scrollToPosition);
    }, 0);
  }
});
