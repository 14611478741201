let elementPopupContainer = null;
let elementPopupContent = null;

function toggleBodyScroll(enable) {
  if (enable) {
    document.body.style.overflow = 'auto';
  } else {
    document.body.style.overflow = 'hidden';
  }
}

function addPopupContainer() {
  if (elementPopupContainer) {
    return;
  }

  elementPopupContainer = document.createElement('div');
  elementPopupContainer.id = 'popup__container';

  document.body.appendChild(elementPopupContainer);

  var elementPopupContentContainer = document.createElement('div');
  elementPopupContentContainer.classList.add('content');
  
  elementPopupContainer.appendChild(elementPopupContentContainer);

  elementPopupContent = document.createElement('div');
  elementPopupContent.classList.add('popup__content');
  
  elementPopupContentContainer.appendChild(elementPopupContent);
  
  toggleBodyScroll(false); // Disable scrolling when modal is open

  // Add event listener to the container to close on background click
  elementPopupContainer.addEventListener('click', function(event) {
    if (event.target !== elementPopupContainer) {
      return;
    }

    removePopupContainer();
  });
}

function removePopupContainer() {
  if (!elementPopupContainer) {
    return;
  }

  document.body.removeChild(elementPopupContainer);

  // Reset the variables to null
  elementPopupContainer = null;
  elementPopupContent = null;

  toggleBodyScroll(true); // Enable scrolling when modal is destroyed
}

export function addPopup(popup) {
  addPopupContainer();

  elementPopupContent.appendChild(popup);
  
  const closeButtonDiv = document.createElement('div');
  closeButtonDiv.classList.add("close");
  
  const closeButton = document.createElement('button');
  closeButton.innerText = 'Close';
  closeButton.onclick = function() {
    removePopup(popup.id);
  };
  
  closeButtonDiv.prepend(closeButton);

  popup.style.position = 'relative';
  popup.prepend(closeButtonDiv);
}

export function removePopup(popupId) {
  const popupToRemove = elementPopupContent.querySelector('#'+popupId);
  
  if (popupToRemove) {
    // Remove the specific popup div by id
    elementPopupContent.removeChild(popupToRemove);

    // Check if elementPopupContent has no more child nodes
    if (!elementPopupContent.hasChildNodes()) {
      // If it's empty, remove the entire background element
      removePopupContainer();
    }
  }
}
