import * as Page from './../function/page.js';

function clamp( val, min, max ) {
  return Math.min( Math.max( min, val ), max );
}

var header = document.getElementById("nav-header");
var footer = document.getElementById("nav-footer");
var lastScrollYPosition;
var headerHeight = header.offsetHeight;

var headerState = {
  VISIBLE: 0,
  HIDDEN:  1,
  SLIDING: 2
}
var headerClass = {
  VISIBLE: 'nav-header--visible',
  HIDDEN:  'nav-header--hidden',
  SLIDING: 'nav-header--sliding'
}
var currentHeaderState = headerState.VISIBLE;
var currentHeaderClass = headerClass.VISIBLE;
var currentHeaderSlidingY = 0;

function headerClassChange(className, toggle, debugHint) {
  if (toggle) {
    if (!header.classList.contains(className)) {
      header.classList.add(className);
      // console.log("[", debugHint, "] Header class added:", className);
    } 
    // else {
    //   console.log("[", debugHint, "] Header class already exists:", className);
    // }
  } else {
    if (header.classList.contains(className)) {
      header.classList.remove(className);
      // console.log("[", debugHint, "] Header class removed:", className);
    } 
    // else {
    //   console.log("[", debugHint, "] Header class not found for removal:", className);
    // }
  }
}

function setHeaderTransform(newY) {
  if (header.classList.contains('nav-header--pill')) {
    header.style.transform = 'translate(-50%, ' + newY + 'px)';
  } else {
    header.style.transform = 'translateY(' + newY + 'px)';
  }
}

function changeHeaderState(newState, delta) {
  if ( currentHeaderState == newState ) return;

  var newClass;
  var newY;

  switch (newState) {
    case headerState.VISIBLE: default:
      newClass = headerClass.VISIBLE;
      break;
    case headerState.HIDDEN:
      newClass = headerClass.HIDDEN;
      newY = -headerHeight;
      break;
    case headerState.SLIDING:
      newClass = headerClass.SLIDING;

      if ( currentHeaderState == headerState.VISIBLE ) {
        // currentHeaderSlidingY = Page.getScrollPositionY();
        currentHeaderSlidingY = lastScrollYPosition;
        newY = currentHeaderSlidingY;
        // newY = currentHeaderSlidingY + ( -delta || 0 );
      } else {
        currentHeaderSlidingY = ( Page.getScrollPositionY() - headerHeight );
        newY = currentHeaderSlidingY;
      }
      break;
  }

  if (newClass && newClass != currentHeaderClass) {
    headerClassChange( newClass, true, "1" );
    setHeaderTransform( newY );
    headerClassChange( currentHeaderClass, false, "2" );

    currentHeaderClass = newClass;
  }

  currentHeaderState = newState;
}

function checkTop() {
  // if ( lastScrollYPosition <= 0 ) {
  //   header.classList.add( 'nav-header--attop' );
  // } else {
  //   header.classList.remove( 'nav-header--attop' );
  // }

  if ( lastScrollYPosition <= 0 ) {
    headerClassChange( 'nav-header--attop-absolutely', true, "3" );
  } else {
    headerClassChange( 'nav-header--attop-absolutely', false, "4" );
  }

  // if ( lastScrollYPosition <= headerHeight && currentHeaderSlidingY == 0 ) {
  if ( lastScrollYPosition <= headerHeight && currentHeaderSlidingY < 8 ) {
  // if ( lastScrollYPosition <= headerHeight && header.classList.contains( 'nav-header--attop-absolutely' ) ) {
    headerClassChange( 'nav-header--attop', true, "5" );
  } else {
    headerClassChange( 'nav-header--attop', false, "6" );
  }
}

function updateAutoHide() {
  var currentScrollYPosition = Page.getScrollPositionY();
  var atBottom = currentScrollYPosition + footer.offsetHeight > document.documentElement.scrollHeight-window.innerHeight;
  var currentTop = currentHeaderSlidingY;

  if ( currentScrollYPosition <= 0 || atBottom ) {
    if (atBottom && currentHeaderState != headerState.VISIBLE) {
      header.style.position = 'fixed';

      if ( currentHeaderState == headerState.SLIDING ) {
        setHeaderTransform( clamp( currentTop - currentScrollYPosition, -headerHeight, 0 ) );
      }
      else {
        setHeaderTransform( -headerHeight );
      }

      setTimeout(function() {
        headerClassChange( 'nav-header--atbottom', true, "7" );

        changeHeaderState( headerState.VISIBLE );

        header.style.position = '';
      }, 0 );
    } else {
      if (!atBottom) headerClassChange( 'nav-header--atbottom', false, "8" );

      changeHeaderState( headerState.VISIBLE );
    }
  }
  else {
    headerClassChange( 'nav-header--atbottom', false, "9" );

    if (lastScrollYPosition) {
      var delta = currentScrollYPosition - lastScrollYPosition;

      if ( delta != 0 ) {
        if (
          currentHeaderState == headerState.VISIBLE && delta > 0 ||
          currentHeaderState == headerState.HIDDEN && delta < 0
        ) {
          changeHeaderState( headerState.SLIDING, delta );
        } else if ( currentHeaderState == headerState.SLIDING ) {
          if ( delta > 0 && currentScrollYPosition - currentTop >= headerHeight ) {
            changeHeaderState( headerState.HIDDEN );
          }
          else if ( delta < 0 && currentScrollYPosition <= currentTop ) {
            changeHeaderState( headerState.VISIBLE );
          }
        }
      }
    }
  }

  lastScrollYPosition = currentScrollYPosition;

  checkTop();
}

window.addEventListener("resize", function() {
  headerHeight = header.offsetHeight;
});

window.addEventListener("DOMContentLoaded", ()=> {
  setTimeout(function() { // hack to fix non-zero value set on start by updateAutoHide double running
    window.addEventListener("scroll", updateAutoHide);

    lastScrollYPosition = Page.getScrollPositionY();

    checkTop();
  }, 1);
});
