// classes.js

/**
 * Returns whether the given element has the given class.
 *
 * @param {Element} element
 * @param {string} className
 * @returns {boolean}
 */
export function hasClass(element, className) {
	className = " " + className + " ";
	return (" " + element.className + " ").replace(/[\n\t]/g, " ").indexOf(className) > -1
}

export function addClass(elements, className) {
  if ( elements.length === undefined ) elements = [elements];
  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    if (!hasClass(element, className)) {
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className = ( element.className + ' ' + className ).replace( /\s+/g, ' ');
      }
    }
  }
}

export function removeClass(elements, className) {
  if ( elements.length === undefined ) elements = [ elements ];
  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    if (hasClass(element, className)) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    }
  }
}

export function toggleClass( elements, className ) {
  if ( elements.length === undefined ) elements = [ elements ];
  for ( var i = 0; i < elements.length; i++ ) {
    var element = elements[ i ];

    if ( hasClass( element, className ) ) {
      removeClass( element, className );
    }
    else {
      addClass( element, className );
    }
  }
}
