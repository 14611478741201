import * as Event from './../function/event.js';
import * as ModuleClass from './../function/class.js';
import * as Element from './../function/element.js';
import * as ThrottleGate from './../function/throttle_gate.js';

// disabled_shake.js

var root = document.documentElement;

var lastMouseX = 0;
var lastMouseY = 0;

function updateMouseCoords(coordX, coordY) {
  lastMouseX = coordX;
  lastMouseY = coordY;
}

function isMouseCoordsInElem(elem) {
  var rect = elem.getBoundingClientRect();
  return parseInt(rect.left, 10) < lastMouseX && lastMouseX < parseInt(rect.left + rect.width, 10) && parseInt(rect.top, 10) < lastMouseY && lastMouseY < parseInt(rect.top + rect.height, 10);
}

var topLevelElemOverlappedDisabledFound = false;
var topLevelElemOverlapped;

function getTopLevelElemOverlappedProcess(elem) {
  var isWithin = isMouseCoordsInElem(elem);

  if (isWithin) {
    if(topLevelElemOverlappedDisabledFound && Element.getElementStyle(elem).pointerEvents == 'none') return;

    topLevelElemOverlapped = elem;

    if(isElementDisabled(elem)) topLevelElemOverlappedDisabledFound = true;

    var hasDecendants = elem.hasChildNodes();

    if (hasDecendants) {
      var decendants = elem.children;
      var len = decendants.length;

      for (var i = 0; i < len; i++) {
        getTopLevelElemOverlappedProcess(decendants[i]);
      }
    }
  }
}

function getTopLevelElemOverlapped(elem) {
  topLevelElemOverlappedDisabledFound = false;

  getTopLevelElemOverlappedProcess(elem);
}

function isElementDisabled(element) {
  return element && (element.disabled || ModuleClass.hasClass(element, 'disabled'));
}

function tryDisabledClickRejection(elem) {
  if (isElementDisabled(elem)) {
    ModuleClass.addClass(elem, 'show-disabled-animation');

    setTimeout(function() {
      ModuleClass.removeClass(elem, 'show-disabled-animation');
    }, throttleGateIntervals['click']);
  }
}

function tryClick(event) {
  if(ThrottleGate.debounceThrottleGate('click', 'click', tryClick, event)) return;

  updateMouseCoords(event.x, event.y);
  getTopLevelElemOverlapped(root);

  if (topLevelElemOverlapped && isElementDisabled(topLevelElemOverlapped)) {
    tryDisabledClickRejection(topLevelElemOverlapped.parentNode);

    var elems = topLevelElemOverlapped.parentNode.children;
    var len = elems.length;

    for (var i = 0; i < len; i++) {
      var elem = elems[i];
      tryDisabledClickRejection(elem);
    }
  }
}

function tryDisabledCursor() {
  getTopLevelElemOverlapped(root);

  if( isElementDisabled(topLevelElemOverlapped) ) {
    ModuleClass.addClass(root, 'show-disabled-cursor');
  }
  else {
    ModuleClass.removeClass(root, 'show-disabled-cursor');
  }
}

function tryScroll() {
  if(ThrottleGate.debounceThrottleGate('mousemove', 'scroll', tryScroll, false)) return;

  tryDisabledCursor();
}

function tryMousemove(event) {
  if(ThrottleGate.debounceThrottleGate('mousemove', 'mousemove', tryMousemove, event)) return;

  updateMouseCoords(event.x, event.y);
  tryDisabledCursor();
}

var areDisabledElements = false;

function initDisabledShake( force ) {
  if ( areDisabledElements ) return; // already running;

  if ( !force )
  {
    var inputs = document.querySelectorAll('input, textarea');

    for ( var i = 0; i < inputs.length; i++ )
    {
      if ( inputs[ i ].disabled )
      {
        areDisabledElements = true;
        break;
      }
    }
  }

  if ( areDisabledElements || force ) {
    Event.addEvent( 'click', document, function(event) {
      // event.preventDefault(); // only if over disabled element || is click anim throttled?
      tryClick(event);
    });

    Event.addEvent('scroll', document, function() {
      tryScroll();
    });

    Event.addEvent('mousemove', document, function(event) {
      tryMousemove(event);
    });
  }
}

initDisabledShake();

// https://javascript.info/dispatch-events#custom-events:

// additional details come with the event to the handler
document.addEventListener( "updateDisabledShake", function( event ) {
  initDisabledShake( event.detail.force );

  if ( event.detail.event )
  {
    updateMouseCoords(event.detail.event.clientX, event.detail.event.clientY);
    tryDisabledCursor();
  }
} );

// document.dispatchEvent( new CustomEvent( "updateDisabledShake", { detail: { force: true } } ) );
