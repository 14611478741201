// throttle_gate.js

var throttleGateKeys = {
  mousemove: false,
  click: false
};

var throttleGateTimes = {
  mousemove: 0,
  click: 0
};

var throttleGateDebounceEvents = {
  mousemove: false,
  scroll: false,
  click: false
};

window.throttleGateIntervals = {
  mousemove: 100,
  click: 200 // 200ms is disabled animation duration
};

var throttleGateDebounceIntervalMin = 10;

export function throttleGate(affectedKey, intervalKey) {
// function throttleGate(affectedKey, forceGate) {
  if (throttleGateKeys[intervalKey]) return true;
  // if (throttleGateKeys[affectedKey] || forceGate) return true;

  throttleGateKeys[affectedKey] = true;

  setTimeout(function() {
    throttleGateKeys[affectedKey] = false;
  }, throttleGateIntervals[affectedKey]);

  throttleGateTimes[affectedKey] = (new Date()).getTime(); // for debounce timing

  return false;
}

export function debounceThrottleGate(key, debounceKey, func, event) {
  var intervalKey = key;

  if (key != 'click') {
    if(throttleGateDebounceEvents['click']) {
      // if within current click debounce time and inside last clicked rectangle.
      //    do not set interval key, instead call true in the throttle gate anyways (forceGate parameter)
      intervalKey = 'click';
    }
  }

  if (throttleGate(key, intervalKey)) {
    var throttleInterval = throttleGateIntervals[intervalKey];

    clearTimeout(throttleGateDebounceEvents[debounceKey]);

    throttleGateDebounceEvents[debounceKey] = setTimeout(function() {
      if (event) {
        func(event);
      }
      else {
        func();
      }
    }, Math.min(Math.max(throttleInterval - ((new Date()).getTime() - throttleGateTimes[intervalKey]), 0), throttleInterval) + throttleGateDebounceIntervalMin);

    return true;
  }

  return false;
}
