import * as ModuleClass from './class.js';

export function insertElementBefore( element, target ) {
  target.parentNode.insertBefore( element, target );
}

export function insertElementAfter( element, target ) {
  target.parentNode.insertBefore( element, target.nextSibling );
}

// insertType = 0: (default) add to end of parentElement, 1: insert before parentElement, 2: insert after parentElement
export function addElement( parentElement, elementType, elementId, elementClasses, elementInnerHTML, insertType ) {
  var newElement = document.createElement( elementType );

  if ( elementId != undefined && elementId != '' ) {
    newElement.id = elementId;
  }

  if ( elementClasses.length === undefined || !Array.isArray( elementClasses ) ) {
    elementClasses = [ elementClasses ];
  }
  for ( var i = 0; i < elementClasses.length; i++ ) {
    var elementClass = elementClasses[ i ];

    ModuleClass.addClass( newElement, elementClass );
  }

  if ( elementInnerHTML != undefined && elementInnerHTML != '' ) {
    newElement.innerHTML = elementInnerHTML;
  }

  switch ( insertType ) {
    case 0: default:
    {
      parentElement.appendChild( newElement );
      break;
    }
    case 1:
    {
      insertElementBefore( newElement, parentElement );
      break;
    }
    case 2:
    {
      insertElementAfter( newElement, parentElement );
      break;
    }
  }

  return newElement;
}

export function addElementBefore( parentElement, elementType, elementId, elementClasses, elementInnerHTML ) {
  return addElement(       parentElement, elementType, elementId, elementClasses, elementInnerHTML, 1 );
}

export function addElementAfter( parentElement, elementType, elementId, elementClasses, elementInnerHTML ) {
  return addElement(      parentElement, elementType, elementId, elementClasses, elementInnerHTML, 2 );
}

export function getElementStyle(element) {
  return window.getComputedStyle ? getComputedStyle(element, null) : element.currentStyle;
}
