export function setCookie(name, value, days, path = '/', domain = null) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  let cookie = name + "=" + (value || "") + expires + "; path=" + path;
  if (domain) {
    cookie += "; domain=" + domain;
  }
  document.cookie = cookie;
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name, path = '/', domain = null) {
  let cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + path;
  if (domain) {
    cookie += "; domain=" + domain;
  }
  document.cookie = cookie;
}
