import * as Event from './../function/event.js';

// inputs.js

function clickInput( event, type ) {
  switch ( type ) {
    case 'summary':
      clickSummary( event );
      break;
    default:
      break;
  }
}

function cascadeDetails( target, open ) {
  if ( open === 'auto' ) open = !target.parentNode.open;
  target.parentNode.open = !open;
  var decendants = target.parentNode.querySelectorAll('details');
  for (var i = 0; i < decendants.length; i++) {
    decendants[i].open = open;
  }
  // var decendants = target.parentNode.querySelectorAll('summary');
  // for (var i = 0; i < decendants.length; i++) {
  //   decendants[i].click();
  // }
}

function clickSummary( event, target ) {
  if ( event.target != target ) return;

  if ( event.shiftKey || event.altKey ) {
    cascadeDetails( target, 'auto' );
  }
  else {
    if ( target.lastClick )
    {
      if ( ( (new Date()).getTime() - target.lastClick ) < doubleClickTime )
      {
        if ( target.parentNode.nodeName == 'DETAILS' )
        {
          var state = target.parentNode.open;
          cascadeDetails( target, state );
        }

        target.lastClick = 0;
        clearTimeout(target.doubleClickTimeout);
      }
    }
    else
    {
      target.lastClick = (new Date()).getTime();

      target.doubleClickTimeout = setTimeout( function() {
        target.lastClick = 0;
      }, doubleClickTime );
    }
  }
}

var inputElems = {};
var doubleClickTime = 250;

function initInputs() {
  if ( inputElems.length ) return; // already running;

  // inputElems['input'] = document.querySelectorAll('input');
  // inputElems['textarea'] = document.querySelectorAll('textarea');
  // inputElems['details'] = document.querySelectorAll('details');
  inputElems['summary'] = document.querySelectorAll('summary');

  if ( inputElems['summary'].length ) {
    for (var i = 0; i < inputElems['summary'].length; i++) {
      Event.addEvent( 'click', inputElems['summary'][i], function(event) {
        clickSummary( event, this );
      });
    }
  }
}

initInputs();
