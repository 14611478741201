import * as Event from './../function/event.js';
import * as ModuleClass from './../function/class.js';

var root = document.documentElement;

// [ tab, left, up, right, down ]
// var navigationKeys = [ 9, 37, 38, 39, 40 ];
var navigationKeys = [ 37, 38, 39, 40 ];
var navigationElements = [ 'textarea', 'input' ];

Event.addEvent("keydown", document, function(event) {
  if ( ModuleClass.hasClass(root, 'show-nav-outline') ) return;

  var needsIndicator = event.keyCode == 9;

  if ( !needsIndicator )
  {
    var isNavigationElement = false;

    var focusedTagName = document.activeElement.tagName.toLowerCase();

    for (var i = 0; i < navigationElements.length; i++ ) {
      if ( focusedTagName == navigationElements[i] ) {
        isNavigationElement = true;
        break;
      }
    }

    if ( isNavigationElement ) {
      for (var i = 0; i < navigationKeys.length; i++ ) {
        if ( event.keyCode == navigationKeys[i] ) {
          needsIndicator = true;
          break;
        }
      }
    }
  }

  // if ( event.keyCode == 9 ) {
  if ( needsIndicator ) {
    ModuleClass.addClass(root, 'show-nav-outline');
  }
});

Event.addEvent("mousedown", document, function() {
  ModuleClass.removeClass(root, 'show-nav-outline');
});
