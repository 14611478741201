export function getScrollPositionY() {
  // Modern browsers including Edge
  if ('scrollY' in window) {
    return window.scrollY;
  }
  // Older browsers: check if pageYOffset is defined
  else if ('pageYOffset' in window) {
    return window.pageYOffset;
  }
  // Internet Explorer, depending on the document mode
  else if (document.documentElement) {
    return document.documentElement.scrollTop;
  }
  // Fallback for older versions of IE
  else if (document.body) {
    return document.body.scrollTop;
  }
  // Default fallback in case none of the above work
  return 0;
}
