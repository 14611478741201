import * as Popup from './popup.js';
import { setCookie, getCookie } from './../function/cookie.js';

const isDisabled = true;
const isTestingConsentNotice = false; // Set this to true to force the display of the consent notice, regardless of consent status

function setPreference(name, value) {
  setCookie(name, value, 365);
}

function setPreferenceAll(value) {
  setPreference('consent_functional', value);
  setPreference('consent_analytics', value);
  setPreference('consent_advertisement', value);
}

let consentNotice = null;

document.addEventListener('DOMContentLoaded', function() {
  if (isDisabled) {
    return;
  }

  function addConsentNotice() {
    if (isTestingConsentNotice || !getCookie('consent_notice')) {
      consentNotice = document.createElement('div');
      consentNotice.id = 'consent-notice';
      consentNotice.className = 'clearfix';
      consentNotice.innerHTML = `
        <div class="consent-message">
          <h1 class="toc-ignore">Data Consent Notice</h1>
          <p>This site uses cookies, local storage, and other technologies to improve your experience and personalize content. For details or to adjust your preferences, please visit the <a href="/html/policy/cookie.html">Cookie Policy</a>.</p>
          <div class="row bottons--no-padding">
            <div class="col-default-12 col-phone-large-6 col-tablet-small-4 col-desktop-small-4"><button type="button" id="acceptConsent" class="button--small" style="background-color:#6afe6a;color:#000000">Accept All</button></div>
            <div class="col-default-12 col-phone-large-6 col-tablet-small-4 col-desktop-small-4"><button type="button" id="rejectConsent" class="button--small" style="background-color:#ff6969;color:#000000">Essential Only</button></div>
            <div class="col-default-12 col-phone-large-12 col-tablet-small-4 col-desktop-small-4"><button type="button" id="manageConsent" class="button--small" style="background-color:#c1c1c1;color:#000000">Customize</button></div>
          </div>
        </div>`;

      var buttons = consentNotice.getElementsByTagName('button');

      buttons[0].addEventListener('click', function() {
        setPreferenceAll('true');
        removeConsentNotice();
      });

      buttons[1].addEventListener('click', function() {
        setPreferenceAll('false');
        removeConsentNotice();
      });

      buttons[2].addEventListener('click', function() {
        addConsentPreferences();
      });

      var stickyAttachmentContentDiv = document.getElementById('stickyattachmentcontent');

      if (stickyAttachmentContentDiv) {
        stickyAttachmentContentDiv.appendChild(consentNotice);
      }
    }
  }

  addConsentNotice(); // Call the function on page load
});

window.addConsentPreferences = addConsentPreferences;

function removeConsentNotice(instantRemove = false) {
  setPreference('consent_notice', 'true');

  var consentNotice = document.getElementById('consent-notice');

  if (consentNotice) {
    if (instantRemove) {
      // Immediate removal of the consent notice
      consentNotice.remove();
      document.dispatchEvent(new CustomEvent("refreshFrame"));
    } else {
      // Animated removal of the consent notice
      consentNotice.classList.add('closing');
      consentNotice.style.height = consentNotice.offsetHeight + 'px';

      setTimeout(function() {
        consentNotice.style.height = '0px';
      }, 1);

      setTimeout(function() {
        if (!isTestingConsentNotice) {
          consentNotice.remove(); // Remove the consent notice from the DOM after transition
        }
        document.dispatchEvent( new CustomEvent( "refreshFrame" ) );
      }, 500); // Adjust this time to match any CSS transitions
    }
  }
}

function addConsentPreferences() {
  // Create the container for the consent management details
  const popup = document.createElement('div');
  popup.id = 'consent-management';
  popup.innerHTML = `
    <h1 class="toc-ignore">Data Consent Management</h1>

    <h2 class="toc-ignore">About</h2>

    <p>This site uses various technologies to enhance navigation, perform essential site functions, and improve our service. This includes cookies, local storage, and possibly other tracking technologies. You will find detailed information about all types of data storage under each consent category below.</p>

    <h2 class="toc-ignore">Data Removal</h2>

    <p>For details on how to remove cookies or local storage, please visit the <a href="/html/policy/cookie.html">Cookie Policy</a>.</p>

    <h2 class="toc-ignore">Settings</h2>

    <div class="row bottons--no-padding">
      <div class="col-default-12 col-phone-large-6"><button type="button" id="acceptConsent" class="button--small" style="background-color:#6afe6a;color:#000000">Accept All</button></div>
      <div class="col-default-12 col-phone-large-6"><button type="button" id="rejectConsent" class="button--small" style="background-color:#ff6969;color:#000000">Essential Only</button></div>
    </div>

    <div class="consent_container div--content content--inset">
      <h3 class="toc-ignore">Essential</h3>
      <input id="switch_checkbox_essential" class="switch" name="Essential Data Can Not Be Disabled" title="Essential Data Can Not Be Disabled" type="checkbox" checked disabled>
      <p>These data points are strictly necessary to provide you with services available through our Website and to use some of its features, such as managing your session and security preferences.</p>
      <details>
        <summary>Details</summary>
        <div class="space"></div>
        <div class="div--content">
          <table>
            <tr><td>Name:</td><td>VISITOR_PRIVACY_METADATA</td></tr>
            <tr><td>Purpose:</td><td>Stores the user's cookie consent state for the current domain.</td></tr>
            <tr><td>Provider:</td><td>.youtube.com</td></tr>
            <tr><td>Service:</td><td>YouTube <a class="link--external" href="https://business.safety.google/privacy/">View Service Privacy Policy</a></td></tr>
            <tr><td>Type:</td><td>server_cookie</td></tr>
            <tr><td>Expires in:</td><td>5 months 27 days</td></tr>
          </table>
        </div>
      </details>
    </div>

    <div class="consent_container div--content content--inset">
      <h3 class="toc-ignore">Functional</h3>
      <input id="switch_checkbox_functional" class="switch" name="Toggle Functional Data" title="Toggle Functional Data" type="checkbox">
      <p>These data points are strictly necessary to provide you with services available through our Website and to use some of its features, such as managing your session and security preferences.</p>
      <details>
        <summary>Details</summary>
        <div class="space"></div>
        <div class="div--content">
          <table>
            <tr><td>Name:</td><td>UserPreferences</td></tr>
            <tr><td>Purpose:</td><td>Stores user settings such as font size and preferences within the HTML Unicode Character Table and Keyboard demo to enhance usability and interface customization.</td></tr>
            <tr><td>Provider:</td><td>` + window.location.hostname + ` (this site)</td></tr>
            <tr><td>Type:</td><td>html_local_storage</td></tr>
            <tr><td>Expires in:</td><td>N/A (persists until cleared by user or application)</td></tr>
          </table>
          <table>
            <tr><td>Name:</td><td>dukenukem</td></tr>
            <tr><td>Purpose:</td><td>Manage the checkout flow for job posts on the ModDB platform.</td></tr>
            <tr><td>Provider:</td><td>www.moddb.com</td></tr>
            <tr><td>Service:</td><td>ModDB <a class="link--external" href="https://www.moddb.com/privacy-policy">View Service Privacy Policy</a></td></tr>
            <tr><td>Type:</td><td>server_cookie</td></tr>
            <tr><td>Expires in:</td><td>1 month</td></tr>
          </table>
          <table>
            <tr><td>Name:</td><td>masterchief</td></tr>
            <tr><td>Purpose:</td><td>Session cookie for logged in users on the ModDB platform.</td></tr>
            <tr><td>Provider:</td><td>www.moddb.com</td></tr>
            <tr><td>Service:</td><td>ModDB <a class="link--external" href="https://www.moddb.com/privacy-policy">View Service Privacy Policy</a></td></tr>
            <tr><td>Type:</td><td>server_cookie</td></tr>
          <tr><td>Expires in:</td><td>10 minutes</td></tr>
          </table>
        </div>
      </details>
    </div>

    <div class="consent_container div--content content--inset">
      <h3 class="toc-ignore">Analytics</h3>
      <input id="switch_checkbox_analytics" class="switch" name="Toggle Analytics Data" title="Toggle Analytics Data" type="checkbox">
      <p>This data helps us and third parties understand how our website is being used, which helps us improve user experience. It tracks user activity and collects statistical information.</p>
      <details>
        <summary>Details</summary>
        <div class="space"></div>
        <div class="div--content">
          <table>
            <tr><td>Name:</td><td>NID</td></tr>
            <tr><td>Purpose:</td><td>Set by Google to set a unique user ID to remember user preferences. Persistent cookie that stays for 182 days.</td></tr>
            <tr><td>Provider:</td><td>.google.com</td></tr>
            <tr><td>Service:</td><td>Google <a class="link--external" href="https://business.safety.google/privacy/">View Service Privacy Policy</a></td></tr>
            <tr><td>Type:</td><td>server_cookie</td></tr>
            <tr><td>Expires in:</td><td>6 months</td></tr>
          </table>
        </div>
      </details>
    </div>

    <div class="consent_container div--content content--inset">
      <h3 class="toc-ignore">Advertisement</h3>
      <input id="switch_checkbox_advertisement" class="switch" name="Toggle Advertisement Data" title="Toggle Advertisement Data" type="checkbox">
      <p>This data is used to deliver targeted advertising and promotional messages. It includes tracking across websites to provide you with personalized advertising based on your interests.</p>
      <details>
        <summary>Details</summary>
        <div class="space"></div>
        <div class="div--content">
          <table>
            <tr><td>Name:</td><td>VISITOR_INFO1_LIVE</td></tr>
            <tr><td>Purpose:</td><td>YouTube is a Google-owned platform for hosting and sharing videos. YouTube collects user data through videos embedded in websites, which is aggregated with profile data from other Google services in order to display targeted advertising to web visitors across a broad range of their own and other websites. Used by Google in combination with SID to verify Google user account and most recent login time.</td></tr>
            <tr><td>Provider:</td><td>.youtube.com</td></tr>
            <tr><td>Service:</td><td>YouTube <a class="link--external" href="https://business.safety.google/privacy/">View Service Privacy Policy</a></td></tr>
            <tr><td>Type:</td><td>server_cookie</td></tr>
            <tr><td>Expires in:</td><td>5 months 27 days</td></tr>
          </table>
          <table>
            <tr><td>Name:</td><td>YSC</td></tr>
            <tr><td>Purpose:</td><td>YouTube is a Google-owned platform for hosting and sharing videos. YouTube collects user data through videos embedded in websites, which is aggregated with profile data from other Google services in order to display targeted advertising to web visitors across a broad range of their own and other websites. Used by Google in combination with SID to verify Google user account and most recent login time.</td></tr>
            <tr><td>Provider:</td><td>.youtube.com</td></tr>
            <tr><td>Service:</td><td>YouTube <a class="link--external" href="https://business.safety.google/privacy/">View Service Privacy Policy</a></td></tr>
            <tr><td>Type:</td><td>server_cookie</td></tr>
            <tr><td>Expires in:</td><td>session (deleted when the browser is closed)</td></tr>
          </table>
        </div>
      </details>
    </div>
  `;

  Popup.addPopup(popup);

  function checkboxUpdate(element, name) {
    element.checked = (getCookie(name) === 'true');
  }

  function checkboxUpdateAll() {
    checkboxUpdate(checkbox_functional, 'consent_functional');
    checkboxUpdate(checkbox_analytics, 'consent_analytics');
    checkboxUpdate(checkbox_advertisement, 'consent_advertisement');
  }

  function checkboxBind(element, name) {
    element.addEventListener('change', function() {
      setPreference(name, this.checked ? 'true' : 'false');
    });
  }

  const checkbox_functional = popup.querySelectorAll('#switch_checkbox_functional')[0];
  const checkbox_analytics = popup.querySelectorAll('#switch_checkbox_analytics')[0];
  const checkbox_advertisement = popup.querySelectorAll('#switch_checkbox_advertisement')[0];
  
  popup.querySelectorAll('#acceptConsent')[0].addEventListener('click', function() {
    setPreferenceAll('true');
  
    checkboxUpdateAll();
  });

  popup.querySelectorAll('#rejectConsent')[0].addEventListener('click', function() {
    setPreferenceAll('false');
  
    checkboxUpdateAll();
  });

  checkboxBind(checkbox_functional, 'consent_functional');
  checkboxBind(checkbox_analytics, 'consent_analytics');
  checkboxBind(checkbox_advertisement, 'consent_advertisement');

  checkboxUpdateAll();

  // Attach a click event listener to each container
  popup.querySelectorAll('.consent_container').forEach(container => {
    container.addEventListener('click', (event) => {
      // Prevent the click from propagating to other elements
      event.stopPropagation();
  
      // Use closest to check if the click was within an input, summary, table, or anchor
      if (event.target.closest('input, details, a')) {
          return; // Exit if the clicked element is any input, summary, table, or anchor
      }

      // Get the 'summary' element within the clicked container
      const detailsElement = container.querySelector('details');
      
      if (detailsElement.hasAttribute('open')) {
          detailsElement.removeAttribute('open');
      } else {
          detailsElement.setAttribute('open', true);
      }
    });
  });

  if (consentNotice) {
    removeConsentNotice(true);
  }
}
