var root = document.documentElement;

// var isScrolling;

// Listen for scroll events
window.addEventListener('scroll', function ( event ) {
	// window.clearTimeout( isScrolling );

  root.classList.add('touchscrolled');

	// isScrolling = setTimeout(function() {
	// 	// console.log( 'Scrolling has stopped.' );
  //   root.classList.remove('touchscrolled');
	// }, 150);

}, false);

window.addEventListener('touchstart', function(){
  root.classList.remove('touchscrolled');
});
