import * as Element from './../function/element.js';

var copyrightyear = document.getElementById('copyrightyear');

if ( copyrightyear && copyrightyear.textContent != new Date().getFullYear() ) {
  copyrightyear.textContent = new Date().getFullYear() + ' ';
}

// =============================================================================
// frame.js
// =============================================================================

var root = document.documentElement;
// var body = document.body;
// var paddingTopElement = document.getElementById('padding-top');
// var paddingBottomElement = document.getElementById('padding-bottom');
var element_main = document.querySelector('main');

root.classList.add('show-resize-anim');

function getElemHeight ( el ) {
  return el.getBoundingClientRect().height || el.offsetHeight;
}

function onResize_applyHeight(target, lookAtElem, targetStyle) {
  var lookAtElemHeight = ( lookAtElem.getAttribute('contentmonitorheight') || getElemHeight( lookAtElem ) ) + 'px';

  // console.log( lookAtElem.getAttribute("id") + " " + lookAtElemHeight );

  if (targetStyle != lookAtElemHeight) {
    target.style[targetStyle] = lookAtElemHeight;
  }
}

var last_resize_size = window.innerWidth + window.innerHeight;

function determineResizeAnim() {
  var current_size = window.innerWidth + window.innerHeight;

  if (Math.abs(last_resize_size - current_size) > 100) {
    root.classList.remove('show-resize-anim');

    setTimeout(function() {
      root.classList.add('show-resize-anim');
    }, 1);
  } else {
    root.classList.add('show-resize-anim');
  }

  last_resize_size = current_size;
}

// window.addEventListener("resize", resized);
// // When All The HTML/DOM Is Ready:
// window.addEventListener("DOMContentLoaded", resized);

// =============================================================================
// backtotop.js
// =============================================================================

var backtotopRequired = 150; // 66; // 50 + 16;

function backtotopVisible() {
  return root.scrollHeight - root.clientHeight > backtotopRequired;
  // return root.clientHeight && (root.scrollHeight / root.clientHeight > 1.25);
}

var backtotopwrap = document.getElementById("backtotopwrap");
var footer = document.getElementById("nav-footer");

var backtotopVisibileTimer;

function getScrollY() {
  return window.scrollY || document.documentElement.scrollTop;
}

function backtotopVisibility() {
  // console.log(getScrollY() / root.clientHeight);
  // console.log(getScrollY());
  if (
    backtotopVisible() &&
    (
      // ( root.clientHeight ? (getScrollY() / root.clientHeight > 0.125) : ( getScrollY() > 250 ) ) ||
      // getScrollY() > 250 ||
      getScrollY() > backtotopRequired ||
      ( getScrollY() > 0 && document.documentElement.scrollTop + getElemHeight( footer ) > document.documentElement.scrollHeight-window.innerHeight )
    )
  ) {
    backtotopwrap.classList.add('visible');
  } else {
    backtotopwrap.classList.remove('visible');
  }
}

// window.addEventListener("scroll", backtotopVisibility);

// =============================================================================
// sticky.js
// =============================================================================

var bottomWrap = document.getElementById("bottomwrap");

var footer = document.getElementById("nav-footer");
var stickycontent = document.getElementById("stickycontent");

function updateAtbottom() {
  if (getScrollY() + getElemHeight( footer ) > document.documentElement.scrollHeight-window.innerHeight) {
    stickycontent.classList.add('atbottom');
  }
  else {
    stickycontent.classList.remove('atbottom');
  }
}

function scrollUpdate() {
  updateAtbottom();
  backtotopVisibility();
  // setTimeout(function() {
  //   refreshFrame();
  // }, 500); // 500ms is resize mixin duration
}

window.addEventListener("scroll", scrollUpdate);

var backtotop = document.getElementById("backtotop");
var backtotopWrap = document.getElementById("backtotopwrap");
var stickyWrap = document.getElementById("stickywrap");
var stickyattachmentWrap = document.getElementById("stickyattachmentwrap");
var stickyattachmentContent = document.getElementById("stickyattachmentcontent");
var contentMonitor = document.getElementById("contentmonitor");

var backtotopSize = backtotop.offsetWidth;
var backtotopMargin = parseFloat(Element.getElementStyle(backtotopWrap).marginBottom) || 0;
var backtotopHeight = backtotopSize + backtotopMargin;
var backtotopClearance = ( backtotopSize + (backtotopMargin * 2) ) * 2;

function updateBottomContent() {

  backtotopVisibility();

  // window.innerHeight
  // console.log(root.clientHeight);
  // console.log(root.scrollHeight - root.clientHeight > 150);

  // var backtotopVisible = root.scrollHeight - root.clientHeight > 150;

  // if (root.scrollHeight - root.clientHeight > 150) {
  //
  // }

  // ---------------------------------------------------------------------------
  // stickyattachmentWrap
  // ---------------------------------------------------------------------------
  var height = getElemHeight( stickyattachmentContent );
  // var height = getElemHeight( stickyattachmentContent ) - parseFloat( Element.getElementStyle( stickyattachmentContent.lastChild ).marginBottom );

  stickyattachmentWrap.setAttribute( 'contentmonitorheight', height );

  var backtotopOutofway = !backtotopVisible() || stickyWrap.offsetWidth - backtotopClearance > contentMonitor.offsetWidth;

  if ( backtotopOutofway ) height = 0;

  stickyattachmentWrap.style.height = height + 'px';

  // ---------------------------------------------------------------------------
  // stickycontent
  // ---------------------------------------------------------------------------

  var height_stickycontent = parseFloat( stickyattachmentWrap.getAttribute('contentmonitorheight') );

  if ( !backtotopOutofway ) height_stickycontent += backtotopHeight;

  stickycontent.setAttribute( 'contentmonitorheight', height_stickycontent );

  // ---------------------------------------------------------------------------
  // stickyWrap
  // ---------------------------------------------------------------------------

  var height_stickywrap = parseFloat( stickycontent.getAttribute('contentmonitorheight') );

  stickyWrap.style.height = height_stickywrap + 'px';
  stickyWrap.setAttribute('contentmonitorheight', height_stickywrap );

  // ---------------------------------------------------------------------------
  // bottomWrap
  // ---------------------------------------------------------------------------

  bottomWrap.setAttribute( 'contentmonitorheight', height_stickywrap + getElemHeight( footer ) );

  // ---------------------------------------------------------------------------
  // updateAtbottom
  // ---------------------------------------------------------------------------

  // ensure stick to bottom-of-screen / top-of-footer
  setTimeout(function() {
    updateAtbottom();
  }, 500); // 500ms is resize mixin duration
}

// =============================================================================
// frame.js
// =============================================================================

var header = document.getElementsByTagName("header")[0];

function refreshFrame() {
  updateBottomContent();
  onResize_applyHeight(element_main, bottomWrap, 'paddingBottom');
}

var refreshFrameTimeout;

window.addEventListener("resize", function() {
  determineResizeAnim();

  onResize_applyHeight(element_main, header, 'paddingTop');

  clearTimeout(refreshFrameTimeout);
  refreshFrameTimeout = setTimeout(function() {
    refreshFrame();
  }, 50);
});

// When All The HTML/DOM Is Ready:
window.addEventListener("DOMContentLoaded", function() {
  setTimeout(function() {

    onResize_applyHeight(element_main, header, 'paddingTop');

    refreshFrame();

    setTimeout(function() {
      root.classList.remove('loading');
    }, 250); // 500ms is resize mixin duration

  }, 250); // 500ms is resize mixin duration
});

// https://javascript.info/dispatch-events#custom-events:

// additional details come with the event to the handler
document.addEventListener( "refreshFrame", refreshFrame );

// document.dispatchEvent( new CustomEvent( "refreshFrame", { detail: { enable: true } } ) );
