// events.js

export function addEvent(evnts, elems, func) {
  var evntLength = 1;
  if ( evnts instanceof Array ) evntLength = evnts.length;

  var elemLength = elems.length || 1;
  if ( elems instanceof Array ) elemLength = elems.length;

  for (var i = 0; i < evntLength; i++) {
    var evnt = evntLength == 1 ? evnts : evnts[i];

    for (var i = 0; i < elemLength; i++) {
      var elem = elemLength == 1 ? ( NodeList.prototype.isPrototypeOf(elems) ? elems[0] : elems ) : elems[i];

      if (elem.addEventListener) { // W3C DOM
        elem.addEventListener(evnt, func, false);
      }
      else if (elem.attachEvent) { // IE DOM
        elem.attachEvent('on' + evnt, func);
      }
      else { // No much to do
        elem['on' + evnt] = func;
      }
    }
  }
}
